import React from 'react';

export function MinutesToRead({ words }: { words: number }) {
  const readMins = Math.ceil(words / 200);
  return (
    <span>
      {' · '}
      {readMins}
      {readMins > 1 ? ' minutes to read' : ' minute to read'}
    </span>
  );
}
